import { on } from 'delegated-events';
import scrollSpy from 'simple-scrollspy';

/**
 * ------------------------------------------------------------------------
 * Constantes
 * ------------------------------------------------------------------------
 */

const CONTACT_EMAIL = 'cafes-vie-privee@la-mel.fr';

const Selector = {
  NAVBAR: '.navbar',
  DATA_MAILTO: '[data-mailto]',
};

/**
 * ------------------------------------------------------------------------
 * Application principale
 * ------------------------------------------------------------------------
 */

scrollSpy(Selector.NAVBAR, {
  sectionClass: 'section',
  menuActiveTarget: '.navbar-item',
  activeClass: 'is-active',
  offset: 100,
});

on('click', Selector.DATA_MAILTO, (event) => {
  event.preventDefault();

  window.location.href = `mailto:${CONTACT_EMAIL}`;
});
